<template>
  <div>
    <WorkspaceHeader />

    <div class="container pt-3 mt-5">
      <div class="my-5 text-center">
        <h3 class="text-bold">Agency Help Videos</h3>
        <p>Get more visual guide on how to use the agency platform</p>
      </div>
      <div class="row g-0">
        <div v-for="video in videos" class="mb-5 col-6 px-4" :key="video.id">
          <div class="">
            <iframe
              width="100%"
              height="100%"
              style="aspect-ratio: 4/3; border-radius: 15px;"
              :src="video.video"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';
export default {
  name: 'DFYWizard',
  components: {
    WorkspaceHeader,
  },
  data: () => ({
    videos: [
      {
        id: 1,
        title: 'Overview',
        description:
          'The Complete Suite to Discover, Compose, Create, Plan, Analyze and Automate your Content Strategy.',
        image: 'video-placeholder.png',
        video: 'https://www.youtube.com/embed/ZWlL6R46Csw?si=AxfxenVefalS22Yo',
      },
      {
        id: 2,
        title: 'How to add team members to SA360',
        description:
          'Find, analyze and share top performing content for any topic or industry. You can also find full content articles as well find  GIF’s, Images, Quotes all with a few searches…',
        image: 'content-discovery.png',
        video: 'https://www.youtube.com/embed/NDVvt4pzBR0?si=T9GPDcFhad8-aL62',
      },
      {
        id: 3,
        title: 'How to connect medium blog to SA360 ',
        description:
          'Compose fresh content for your blog or social media from an intuitive editor. Embed content from your favorite sources and find Articles, GIFs, Images, Quotes and more.',
        image: 'composer.png',
        video: 'https://www.youtube.com/embed/VjQQbUgZUf4?si=vYapN9EhVe6hpA6H',
      },
      {
        id: 4,
        title: 'How to connect social accounts to SA360',
        description:
          'Plan, schedule content for all your marketing channels from a single place using our robust Planner Calender and campaign tools',
        image: 'content-planner.png',
        video: 'https://www.youtube.com/embed/HMoy1D48GA0?si=fXmlxRHCEWZx_4Ef',
      },
      {
        id: 5,
        title: 'How to connect WP blog to SA360',
        description:
          'Automate your content discovery and publishing with ready to launch triggers and automation templates',
        image: 'automation.png',
        video: 'https://www.youtube.com/embed/ner_ez0Kd1E?si=bgEGrzr5ZkZWeHsA',
      },
      {
        id: 6,
        title: 'How to use SA360 Blog post composer',
        description: 'See the Numbers and get a statical view of your social media and content performance',
        image: 'video-placeholder.png',
        video: 'https://www.youtube.com/embed/BQlY5oRSKgw?si=uIFmUT4E-qZZIjUw',
      },
      {
        id: 7,
        title: 'How to use SA360 Social post composer',
        description: 'See the Numbers and get a statical view of your social media and content performance',
        image: 'video-placeholder.png',
        video: 'https://www.youtube.com/embed/PkZIWGgjppg?si=JxdyR7HvZjSmDoHH',
      },
      {
        id: 8,
        title: 'How to use the chat feature on SA360',
        description: 'See the Numbers and get a statical view of your social media and content performance',
        image: 'video-placeholder.png',
        video: 'https://www.youtube.com/embed/YLcsM1igFf4?si=C7nWm-C492F590ke',
      },
      {
        id: 9,
        title: 'How to use the Image designer',
        description: 'See the Numbers and get a statical view of your social media and content performance',
        image: 'video-placeholder.png',
        video: 'https://www.youtube.com/embed/bRsgnPTBB98?si=9Tt61LOl6-9czai2',
      },
      {
        id: 10,
        title: 'How to use the SA360 website designer ',
        description: 'See the Numbers and get a statical view of your social media and content performance',
        image: 'video-placeholder.png',
        video: 'https://www.youtube.com/embed/PJ-cRIqzw6k?si=ORF9YrRYZcbuwZbW',
      },
      {
        id: 11,
        title: 'How to use the SA360 AI automations',
        description: 'See the Numbers and get a statical view of your social media and content performance',
        image: 'video-placeholder.png',
        video: 'https://www.youtube.com/embed/y34d6-Is2YA?si=DlxG5z_MX5hTS5g3',
      },
      {
        id: 12,
        title: 'How to set up automations on SA360',
        description: 'See the Numbers and get a statical view of your social media and content performance',
        image: 'video-placeholder.png',
        video: 'https://www.youtube.com/embed/BQlY5oRSKgw?si=RF4LJh01pDB4olUf',
      },
      {
        id: 8,
        title: 'How to use SA360 Social post composer',
        image: 'https://i.ytimg.com/vi/JxdyR7HvZjSmDoHH/mqdefault.jpg',
        video: 'https://www.youtube.com/embed/PkZIWGgjppg?si=BVE8W7GQwcf69R4b',
      },
      {
        id: 9,
        title: 'How to use the Image designer',
        image: 'https://i.ytimg.com/vi/9Tt61LOl6-9czai2/mqdefault.jpg',
        video: 'https://www.youtube.com/embed/bRsgnPTBB98?si=LyQwRAqQoujYw6O7',
      },
      {
        id: 10,
        title: 'How to use the chat feature on SA360',
        image: 'https://i.ytimg.com/vi/C7nWm-C492F590ke/mqdefault.jpg',
        video: 'https://www.youtube.com/embed/YLcsM1igFf4?si=qJBENWel7jNh07-W',
      },
      {
        id: 11,
        title: 'How to use the SA360 website designer',
        image: 'https://i.ytimg.com/vi/ORF9YrRYZcbuwZbW/mqdefault.jpg',
        video: 'https://www.youtube.com/embed/PJ-cRIqzw6k?si=R7RXnuCLdADmBXnd',
      },
      {
        id: 12,
        title: 'How to use the SA360 AI automations',
        image: 'https://i.ytimg.com/vi/DlxG5z_MX5hTS5g3/mqdefault.jpg',
        video: 'https://www.youtube.com/embed/y34d6-Is2YA?si=ZTQpiwSEGOn8H-JM',
      },
      {
        id: 13,
        title: 'How to download DFY assets on SA360',
        image: 'https://i.ytimg.com/vi/plu6vrdNCzH2Vmrr/mqdefault.jpg',
        video: 'https://www.youtube.com/embed/ZWlL6R46Csw?si=txeZZWj016bAaa45',
      },
    ],
  }),
};
</script>

<style></style>
